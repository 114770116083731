<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Edit {{ getTitle }}</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >{{ getTitle }} Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      :placeholder="`${getTitle} Name`"
                      v-model="newSettingValue"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.newSettingValue.$errors)
                      "
                      @blur="v$.newSettingValue.$touch"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      @click="submit"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      :loading="submitButton.isLoading"
                      class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                      color="#5e72e4"
                      >Submit</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import pluralize from "pluralize";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "settings-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      newSettingValue: { required },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newSettingValue: "",
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
    getTitle() {
      const temp = this.type.replace(/-/g, " ");
      const singular = this.titleCase(pluralize.singular(temp));
      return `${singular.charAt(0).toUpperCase()}${singular.slice(1)}`;
    },
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const settingsObject = {
        value: this.newSettingValue,
      };

      this.$http
        .patch(
          `/static-data/${this.id}`,
          settingsObject,
          this.sessionStore.getHttpConfig
        )
        .then(() => {
          this.$notify.success("Setting successfully updated!");
          this.$router.push("/settings");
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the setting. Please try again later or contact support."
          );
          console.error("Error while updating the setting: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    this.$http
      .get(`/static-data/${this.id}`, this.sessionStore.getHttpConfig)
      .then((result) => {
        this.newSettingValue = result.data.data[0].value;
      })
      .catch((error) => {
        this.$notify.error(
          "An error occurred while loading the setting. Please try again later or contact support."
        );
        console.error("Error while loading the setting: ", error);
      });
  },
};
</script>
