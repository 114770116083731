var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0 px-6 pb-16",staticStyle:{"background":"#44ab4a"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"text-h2 font-weight-600",class:{ 'text-white': _vm.primary, 'text-typo': !_vm.primary }},[(_vm.$route.name === 'Dashboard')?[_vm._v("Default")]:[_vm._v(_vm._s(_vm.$route.name))]],2),_c('v-breadcrumbs',[_c('v-breadcrumbs-item',{attrs:{"to":"/dashboard","active-class":"active-breadcrumb"}},[_c('v-icon',{attrs:{"color":_vm.colorActive}},[_vm._v("fas fa-home")])],1),_c('li',{staticClass:"v-breadcrumbs__divider opacity-5",class:{ 'text-white': _vm.primary, 'text-muted': !_vm.primary }},[_vm._v(" - ")]),_c('v-breadcrumbs-item',{class:{
            'text-white': _vm.primary,
            'text-primary': !_vm.primary,
            'font-weight-600': _vm.primary,
          }},[_vm._v(" "+_vm._s(_vm.$route.meta.groupName)+" ")]),_c('li',{staticClass:"v-breadcrumbs__divider opacity-5",class:{ 'text-white': _vm.primary, 'text-muted': !_vm.primary }},[_vm._v(" - ")]),_c('v-breadcrumbs-item',{staticClass:"no-default-hover",class:{
            'text-white': _vm.primary,
            'text-muted': !_vm.primary,
            'breadcrumb-alternative': !_vm.primary,
            'font-weight-600': _vm.primary,
          },attrs:{"active-class":"active-breadcrumb","to":_vm.$route.name.toLowerCase()}},[(_vm.$route.name === 'Dashboard')?[_vm._v("Default")]:[_vm._v(_vm._s(_vm.$route.name))]],2)],1)],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"12","md":"6"}},[(_vm.showBackButton)?_c('v-btn',{staticClass:"me-3 py-1 px-2 font-weight-600 text-capitalize btn-neutral rounded-sm",attrs:{"elevation":"0","small":"","min-width":"45","ripple":false,"color":"#fff"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Back")]):_vm._e(),(_vm.customRightContent)?[_vm._t("rightContent")]:_vm._e()],2)],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }